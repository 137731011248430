import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./App.css";
import ChatPage from "./chat_pages/ChatPage";
import GlobalStyles from "@mui/material/GlobalStyles";
import { CssBaseline } from "@mui/material";
import { useMemo, useEffect, useState } from "react";

const globalStyles = `
  *::-webkit-scrollbar {
    width: 0.4em;
  }
  *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
    border-radius: 10px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #6b6b6b;
    border-radius: 10px;
  }
`;

function App() {
  const [lightDarkMode, setLightDarkMode] = useState("light");
  const theme = useMemo(() => {
    return createTheme({
      palette: {
        mode: lightDarkMode,
        primary: {
          main: '#6DBAC9',
          contrastText: 'white',
        },
        secondary: {
          main: '#B569AF',
          contrastText: 'white',
        }
      },
    });
  }, [lightDarkMode]);

  // adjust the dark mode based on current time 6am to 6pm
  useEffect(() => {
    const now = new Date();
    const currentHour = now.getHours();
    if (currentHour >= 6 && currentHour < 18) {
      setLightDarkMode("light");
    } else {
      setLightDarkMode("dark");
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={globalStyles} />
      <CssBaseline />
      <ChatPage />
    </ThemeProvider>
  );
}

export default App;
