import FlareIcon from "@mui/icons-material/Flare";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import WarningIcon from "@mui/icons-material/Warning";
import { Paper, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";

function ChatTips() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const detailStyle = {
    display: "flex",
    color: "grey.500",
    justifyContent: isSmallScreen ? "flex-start": "center",
  };

  return (
    <>
      <Paper sx={{ p: 2, mb: 1 }} elevation={1}>
        <Typography
          variant="h6"
          sx={{
            color: "grey.500",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          gutterBottom
        >
          <FlareIcon sx={{ marginRight: 1 }} />
          您可以这样问
        </Typography>
        <Typography
          variant="body1"
          sx={detailStyle}
        >
          &bull; 简单的介绍一下量子运算。
        </Typography>
        <Typography
          variant="body1"
          sx={detailStyle}
        >
          &bull; 给10岁的小朋友可以送什么生日礼物?
        </Typography>
        <Typography
          variant="body1"
          sx={detailStyle}
        >
          &bull; 在JavaScript中如何调用HTTP请求?
        </Typography>
      </Paper>
      <Paper sx={{ p: 2, mb: 1 }} elevation={1}>
        <Typography
          variant="h6"
          sx={{
            color: "grey.500",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          gutterBottom
        >
          <FlashOnIcon sx={{ marginRight: 1 }} />
          我可以做到这些
        </Typography>
        <Typography
          variant="body1"
          sx={detailStyle}
        >
          &bull; 当开启"记忆上文对话"时，我可以根据之前最多10条对话进行作答。
        </Typography>
        <Typography
          variant="body1"
          sx={detailStyle}
        >
          &bull; 当开启"记忆上文对话"时，您可以纠正我的错误。
        </Typography>
        <Typography
          variant="body1"
          sx={detailStyle}
        >
          &bull; 我会拒绝回答不合时宜的问题。
        </Typography>
      </Paper>
      <Paper sx={{ p: 2, mb: 1 }} elevation={1}>
        <Typography
          variant="h6"
          sx={{
            color: "grey.500",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          gutterBottom
        >
          <WarningIcon sx={{ marginRight: 1 }} />
          我目前做不到的事情
        </Typography>
        <Typography
          variant="body1"
          sx={detailStyle}
        >
          &bull; 有的时候我可能会提供不准确的信息。
        </Typography>
        <Typography
          variant="body1"
          sx={detailStyle}
        >
          &bull; 有的时候我提供的信息可能存在偏激。
        </Typography>
        <Typography
          variant="body1"
          sx={detailStyle}
        >
          &bull; 2021年之后发生的事情，我知道的很有限。
        </Typography>
      </Paper>
    </>
  );
}

export default ChatTips;
