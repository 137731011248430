import { css } from "@emotion/css";
import { useTheme } from "@mui/material/styles";

export const useChatStyles = () => {
  const theme = useTheme();

  return {
    root: css`
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    `,
    grow: css`
      flex-grow: 1;
    `,
    appBar: css`
      z-index: ${theme.zIndex.drawer + 1};
      flex-wrap: wrap;
      margin-bottom: ${theme.spacing(1)};
    `,
    content: css`
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      flex: 1 1 0%;
      padding-left: ${theme.spacing(1)};
      padding-right: ${theme.spacing(1)};
    `,
    main: css`
      display: flex;
      flex-direction: column;
      height: 100%;
      flex: 1 1 0%;
      overflow: hidden;
    `,
    messageContainer: css`
      padding: ${theme.spacing(1)};
      border-radius: ${theme.shape.borderRadius}px;
      margin-bottom: ${theme.spacing(1)};
      max-width: 70%;
    `,
    botMessage: css`
      background-color: ${theme.palette.secondary.main};
      color: ${theme.palette.secondary.contrastText};
      align-self: flex-start;
    `,
    myMessage: css`
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
      align-self: flex-end;
    `,
    inputContainer: css`
      padding: ${theme.spacing(2)};
      border-top: 1px solid ${theme.palette.divider};
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      justify-content: center;
      z-index: 10;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: ${theme.palette.background.default};
    `,
  };
};
