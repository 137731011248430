import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from "axios";
import {
  AppBar,
  Toolbar,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
} from "@mui/material";
import ChatMessage from "./chat_components/ChatMessage";
import ChatTips from "./chat_components/ChatTips";
import { useChatStyles } from "./ChatPageStyles";
import SendIcon from "@mui/icons-material/Send";
import PetsIcon from "@mui/icons-material/Pets";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";

function ChatPage() {
  const classes = useChatStyles();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  // const [useHistory, setUseHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const end = useRef();
  const textFieldRef = useRef(null);

  const handleSubmit = useCallback(async () => {
    if (inputValue.trim().length === 0) {
      setInputValue("");
      return;
    }
    setIsLoading(true);
    const updatedMessages = [
      ...messages,
      { content: inputValue, role: "user" },
    ];
    setMessages((prev) => [...prev, { content: inputValue, role: "user" }]);
    setInputValue("");
    const reqBody = {
      chatHistory: updatedMessages,
    };
    try {
      const resp = await axios.post(
        "https://apps.gpt.zijiazhang.me/common/chats",
        reqBody
      );
      if (resp.status === 200) {
        setMessages((prev) => [...prev, resp.data.choices[0].message]);
      }
      // function delay(ms) {
      //   return new Promise((resolve) =>
      //     setTimeout(
      //       () =>
      //         resolve(
      //           "To use Stripe in React, follow these steps:\n\n1. Sign up for a Stripe account at stripe.com and get your API keys.\n2. Install the Stripe package in your React application using npm or yarn: `npm install stripe` or `yarn add stripe`\n3. In your React code, import Stripe and create a new instance of the Stripe object using your API key:\n\n```javascript\nimport Stripe from 'stripe';\n\nlet stripe = Stripe('YOUR_STRIPE_API_KEY');\n```\n\n4. Use the Stripe object to make API calls to create, retrieve, update or delete Stripe resources such as customers, cards, charges, subscriptions and plans. Here's an example of creating a charge:\n\n```javascript\nstripe.charges.create({\n  amount: 1000,\n  currency: 'usd',\n  source: 'tok_visa', // replace with a real card token\n  description: 'Example charge'\n}).then(charge => {\n  console.log(charge);\n}).catch(error => {\n  console.error(error);\n});\n```\n\n5. To handle payments with Stripe in your React app, you can use the Stripe Checkout component, which lets users pay with a credit card, Apple Pay or Google Pay. To use it, import the StripeCheckout component from the react-stripe-checkout package and use it in your React code, passing in the required props such as amount, currency and token:\n\n```javascript\nimport StripeCheckout from 'react-stripe-checkout';\n\nfunction handleToken(token) {\n  console.log(token);\n}\n\n<StripeCheckout\n  stripeKey=\"YOUR_PUBLISHABLE_STRIPE_API_KEY\"\n  token={handleToken}\n  amount={1000}\n  currency=\"USD\"\n  name=\"My Store\"\n  description=\"Example charge\"\n/>\n```\n\nThat's it! With these steps, you can use Stripe in your React application to handle payments and manage your customers' data."
      //         ),
      //       ms
      //     )
      //   );
      // }
      // const resp = await delay(3000);
      // setMessages((prev) => [...prev, { content: resp, role: "assistant" }]);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [messages, inputValue]);

  const handleInputChange = useCallback((event) => {
    setInputValue(event.target.value);
  }, []);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  // const handleSwitchChange = () => {
  //   setUseHistory(!useHistory);
  // };

  useEffect(() => {
    if (end.current) {
      end.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            sx={{ display: "flex", alignItems: "center" }}
          >
            <PetsIcon sx={{ marginRight: 1, marginBottom: "4px" }} />
            聪明小狗
          </Typography>
          <div className={classes.grow} />
          {/* <Switch
            checked={useHistory}
            onChange={handleSwitchChange}
            color="secondary"
          />
          <Typography variant="caption" sx={{ pt: "2px" }}>
            结合上文作答
          </Typography> */}
          <IconButton
            onClick={() => {
              setMessages([]);
            }}
          >
            <DeleteIcon sx={{ color: "white" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.main}>
        <div style={{ flex: "1 1 0%", overflow: "hidden" }}>
          <div style={{ height: "100%", overflow: "auto" }}>
            <div
              className={classes.content}
              onTouchMove={() => {
                textFieldRef.current.blur();
              }}
            >
              {messages.map((message, index) => (
                <ChatMessage message={message} index={index} key={index} />
              ))}
              {messages.length === 0 && <ChatTips />}
              <div ref={end} style={{ height: "88px" }}></div>
            </div>
          </div>
        </div>
        <div className={classes.inputContainer}>
          <FormControl fullWidth variant="outlined">
            <TextField
              label="有什么可以帮助您?"
              variant="outlined"
              value={inputValue}
              inputRef={textFieldRef}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading && <CircularProgress />}
                    {!isLoading && (
                      <IconButton
                        color="primary"
                        aria-label="send"
                        disabled={isLoading}
                        onClick={handleSubmit}
                      >
                        <SendIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              multiline
              maxRows={4}
              fullWidth
              onKeyDown={handleKeyDown}
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default ChatPage;
