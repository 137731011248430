import { Typography, Avatar } from "@mui/material";
import { useChatMessageStyles } from "./ChatMessageStyles";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import { React } from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";

const MarkDownMessage = ({ message, classes }) => {
  return (
    <div>
      <ReactMarkdown
        children={message.content}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, "")}
                style={atomDark}
                language={match[1]}
                PreTag="div"
                {...props}
              />
            ) : (
              <code className={classes.inlineCode} {...props}>
                {children}
              </code>
            );
          },
        }}
      />
    </div>
  );
};

function ChatMessage({ message, index }) {
  const classes = useChatMessageStyles();

  if (message.role === "user") {
    return (
      <div
        className={`${classes.chatMessageContainer}`}
        style={{ flexDirection: "row-reverse" }}
      >
        <Avatar
          alt={"You"}
          sx={{ ml: 1, bgcolor: classes.theme.palette.primary.main }}
        >
          <EmojiPeopleIcon />
        </Avatar>
        <Typography
          key={index}
          variant="body1"
          gutterBottom
          className={`${classes.messageContainer} ${classes.myMessage}`}
        >
          {message.content}
        </Typography>
      </div>
    );
  }

  return (
    <div
      className={`${classes.chatMessageContainer}`}
      style={{ flexDirection: "row" }}
    >
      <Avatar
        alt={"Bot"}
        src={process.env.PUBLIC_URL + "/images/cute-puppy.png"}
        sx={{ mr: 1 }}
      />
      <Typography
        key={index}
        variant="body1"
        gutterBottom
        className={`${classes.messageContainer} ${classes.botMessage}`}
      >
        <MarkDownMessage message={message} classes={classes}/>
      </Typography>
    </div>
  );
}

export default ChatMessage;
