import { css } from "@emotion/css";
import { useTheme } from "@mui/material/styles";

export const useChatMessageStyles = () => {
  const theme = useTheme();

  return {
    theme: theme,
    chatMessageContainer: css`
      display: flex;
      margin-bottom: ${theme.spacing(1)};
    `,
    messageContainer: css`
      padding: ${theme.spacing(1)};
      border-radius: ${theme.shape.borderRadius}px;
      max-width: 70%;
      min-width: 50px;
    `,
    botMessage: css`
      background-color: ${theme.palette.secondary.main};
      color: ${theme.palette.secondary.contrastText};
      align-self: flex-start;
    `,
    myMessage: css`
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
      align-self: flex-end;
    `,
    inlineCode: css`
      font-weight: bolder;
    `,
  };
};
